<script>
import ImageCropper from "@/components/ImageCropper.vue";
import api from "@/repo/api";

export default {
    name: "ReportPhoto",
    components: {ImageCropper},
    props: {
        detail: Object
    },
    data() {
        return {
            showHeadModal: false,
            lifePhotos: [],
        }
    },
    mounted() {
        this.loadInfo()
    },
    watch: {
        'detail.extra.life_photos'() {
            this.loadInfo()
        }
    },
    methods: {
        headUploadDone(image) {
            
            this.$loading.show()
            
            
            api.post('/ast-detect/update-photo', {id: this.detail.id, image}, res => {
                
                this.$loading.hide()
                this.$message.data(res)
                
                if (res.code === 0) {
                    this.detail.photo = image
                    this.$forceUpdate()
                    
                    this.showHeadModal = false
                }
                
            })
            
            return true;
        },
        loadInfo() {
            let list = this.detail.extra.life_photos;
            
            if (!list) list = this.detail.member_image.life_photos;
            
            if (!list) list = [];
            
            list = JSON.parse(JSON.stringify(list));
            
            list.unshift(this.detail.report.photo);
            
            this.lifePhotos = list;
        },
        editPhoto() {
            this.showHeadModal = true
        },
        resetPhoto() {
            this.$confirm({
                title: "重置后会以用户检测照片作为纸质报告打印头像.",
                onOk: () => {
                    this.headUploadDone('')
                }
            })
        },
        showReportPhoto() {
            window.open(this.detail.report.photo);
        },
        showReportBody() {
            window.open(this.detail.report.body)
        },
    }
}
</script>

<template>
    <div class="p-image-box">
        <div class="pit p-image"
             :style="`background-image:url(${detail.photo || detail.report.photo})`"></div>
        <div class="sc">
            <div @click="editPhoto">
                <a>修改头像</a>
            </div>
            <div @click="resetPhoto">
                <a>重置头像</a>
            </div>
            <div @click="showReportPhoto">
                <a>查看人脸照</a>
            </div>
            <div v-if="this.detail.report.body" @click="showReportBody">
                <a>查看全身照</a>
            </div>
            <div v-else>
                <span>未上传全身照</span>
            </div>
        </div>
        
        <image-cropper :imageList="lifePhotos" :aspect-ratio="1" v-model="showHeadModal" @cropped="headUploadDone"/>
    
    </div>
</template>

<style scoped lang="less">

.p-image-box {
    position: relative;
    
    .sc {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        opacity: 0;
        transition: all .3s;
        font-size: 12px;
        flex-direction: column;
        
        div {
            margin-bottom: 12px;
        }
    }
    
    &:hover {
        .sc {
            opacity: 1;
        }
    }
}

.p-image {
    width: 133px;
    height: 160px;
    left: 582px;
    top: 171px;
    background-size: cover;
    background-position: center;
}

</style>